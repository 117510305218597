.nav-link-custom {
  color: black; /* Texto negro */
  text-decoration: none; /* Sin subrayado */
  
}

.navbar-container {
  display: flex;
  justify-content: space-between; /* Esto distribuye el espacio al inicio y al final, centrando efectivamente el contenido en medio */
  align-items: center; /* Esto asegura que los elementos estén alineados verticalmente */
}

.links-container {
  border-bottom: 0.5px solid #b1afaf; /* Ajusta el color y el grosor del borde según necesites */
  border: 0.5;
  border-radius: 3rem; /* Ajusta esto para los bordes redondeados */
  padding: .5rem .5rem; /* Ajusta el padding según sea necesario */
  display: inline-block; /* Esto mantiene los elementos en línea y permite que el padding y border-radius afecten el layout */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Ajusta la sombra según necesites */
}

.links-div{
  align-items: center;
}



.custom-button {
  background-color: #DF495A !important;/* Color de fondo */
  border: none !important; /* Elimina el borde del botón */
  border-radius: 50px !important; /* Bordes redondeados, ajusta el px para un redondeo diferente */
  color: white !important; /* Color del texto */
  padding: 10px 20px !important; /* Ajusta el padding para cambiar el tamaño del botón */
  font-family: 'Poppins', sans-serif !important; /* Tipo de letra Poppins */
  font-weight: 600 !important; /* Ajusta el peso de la fuente si es necesario */
  transition: background-color 0.3s ease !important; /* Opcional: transición suave para el hover */
  padding-inline: 5rem !important;
  text-align: center;
}

.custom-button:hover {
  background-color: #C43D56 !important; /* Un color ligeramente más oscuro para el hover */
}


.custom-buttonf {
  background-color: #DF495A !important;/* Color de fondo */
  border: none !important; /* Elimina el borde del botón */
  border-radius: 50px !important; /* Bordes redondeados, ajusta el px para un redondeo diferente */
  color: white !important; /* Color del texto */
  padding: 10px 20px !important; /* Ajusta el padding para cambiar el tamaño del botón */
  font-family: 'Poppins', sans-serif !important; /* Tipo de letra Poppins */
  font-weight: 600 !important; /* Ajusta el peso de la fuente si es necesario */
  transition: background-color 0.3s ease !important; /* Opcional: transición suave para el hover */
  padding-inline: 5rem !important;
  text-align: center;
  max-width: 75% !important;
}

.custom-buttonf:hover {
  background-color: #C43D56 !important; /* Un color ligeramente más oscuro para el hover */
}

@media (max-width: 768px) {
  .custom-buttonf {
    width: 100% !important; /* Hace que el botón ocupe el ancho completo */
    padding-inline: 1rem !important; /* Reduce el padding interior para pantallas pequeñas */
  }
}



@media (min-width: 768px)  {
  .nav-custom-responsive {
    border: px solid ;
    border-bottom: 0.5;
    border-radius: 50px !important;
    padding: 0.5rem 0.5rem !important;
    box-shadow: 0 4px 6px rgba(43, 43, 43, 0.192) !important;
  }
}

.text-poppins {
  font-family: 'Poppins', sans-serif !important; /* Tipo de letra Poppins */
}

.text-documents {
  font-family: 'Source Sans Pro', sans-serif !important


}


.text-home {
  color: #22394E;
}

.responsive-image {
  width: 30%;
  max-width: 50px; /* Ajusta según el tamaño deseado */
  height: auto; /* Mantiene la relación de aspecto */
}

.button-somos {
  background-color: #404040 !important; /* Color de fondo */
  color: #ffffff; /* Color del texto para garantizar la legibilidad */
  width: 70%; /* Ancho del botón */
  border-radius: 50px !important;
  border: none !important; 
  /* Añade cualquier otro estilo como padding, border-radius, etc., según necesites */
}

.blog-card {
  background-color: #cf2437 !important;
  border-radius:  1.5rem;
  width: 800px !important;
  height: auto !important;
}
.blog-card-no-resume {
  background-color: #cf2437 !important;
    border-radius: 1.5rem;
    width: 600px !important;
    height: auto !important;
}

@media screen and (max-width: 768px) {
  .blog-card, .blog-card-no-resume {
    width: 100% !important;
    padding: 0 !important;
  }
  .blog-card-body {
    padding: 1 !important;
    width: 100% !important;
  }
  .blog-card-img {
    display: none;
  }
  .post-p {
    font-size: 18px !important;
  }
  .post-p-small {
    font-size: 14px !important;
  }
  .post-title-h1-primary {
    font-size: 38px !important;
  }
  .post-title-h2-primary {
    font-size: 23px !important;
  }
  
}

.blog-card-img {
  width: 300px !important;
  height: fit-content !important;
}

.blog-card-body {
  background-color: #ffffff !important;
  border: 4px solid #000000 !important; /* Ajusta el color y el grosor del borde según necesites */
  border-radius: 1.5rem; /* Ajusta esto para los bordes redondeados */
  padding: 1.8rem; /* Ajusta el padding según sea necesario */
  display: inline-block; /* Esto mantiene los elementos en línea y permite que el padding y border-radius afecten el layout */
}

.blog-card-title { 
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  font-size: 25px;
  color: #000000;
}

.blog-card-text {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.post-title-h1-primary {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  font-size: 45px;
  color: #385d7f;
  line-height: 40px;
}

.post-title-h2-primary {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 30px;
  color: #101b24;
  line-height: 30px;  
}

.post-p-small {
  font-family: 'Poppins', sans-serif !important;  
  font-weight: 400;
  font-size: 14px;
  color: #101b24;
}

.post-p {
  font-family: 'Poppins', sans-serif !important;  
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  color: #101b24;
}

.button-gracias {
  background-color: #404040 !important; /* Color de fondo */
  color: #ffffff; /* Color del texto para garantizar la legibilidad */
  border-radius: 50px !important;
  width: 50%; /* Ancho del botón */
  border: none !important; 
  /* Añade cualquier otro estilo como padding, border-radius, etc., según necesites */
}

/* Asegúrate de que los estilos sean lo suficientemente específicos para sobreescribir los estilos por defecto */
.carousel .carousel-indicators li {
  background-color: #000 !important; /* Cambia el color de fondo a negro */
  width: 10px !important; /* Ajusta el ancho para que sean más grandes */
  height: 10px !important; /* Ajusta la altura para que sean más grandes */
  border-radius: 50% !important; /* Hace que los indicadores sean redondos */
  margin: 0 2px !important; /* Agrega un pequeño espacio entre los indicadores */
  margin-top: 20px !important;
}

.carousel-indicators [data-bs-target] {
background-color: #404040 !important;
}


.process-tittle{
  color: #385D7F;

}

@media (min-width: 768px)  {
  .process-tittle{

  color: #385D7F;
  margin-top: 6rem;
  margin-left: 6rem;
  }
}

.button-process {
  background-color: #404040 !important; /* Color de fondo */
  color: #ffffff; /* Color del texto para garantizar la legibilidad */
  width: 50%; /* Ancho del botón */
  border-radius: 50px !important;
  border: none !important; 
  margin-left: 6rem !important;
  /* Añade cualquier otro estilo como padding, border-radius, etc., según necesites */
}


.button-process2 {
  background-color: #404040 !important; /* Color de fondo */
  color: #ffffff; /* Color del texto para garantizar la legibilidad */
  width: 60%; /* Ancho del botón */
  border-radius: 50px !important;
  border: none !important; 
  margin-bottom: 30px;
  margin-top: 30px;
  /* Añade cualquier otro estilo como padding, border-radius, etc., según necesites */
}

@media (max-width: 768px)  {
  .button-process{
    background-color: #404040 !important; /* Color de fondo */
    color: #ffffff; /* Color del texto para garantizar la legibilidad */
    width: 70%; /* Ancho del botón */
    border-radius: 50px !important;
    border: none !important; 
    margin-top: 3rem !important;
    margin-inline: 2rem !important;
  }
}


/* Estilos para pantallas pequeñas */
@media (max-width: 767px) {
  .payment-container {
    flex-direction: column; /* Coloca los elementos en columna */
    padding: 10px; /* Ajuste del padding para forma cuadrada */
  }
  .payment-row {
    flex-basis: 50%; /* Asegúrate de que cada fila ocupe la mitad del espacio disponible */
    margin-bottom: 10px; /* Espaciado entre cada fila de imágenes */
    justify-content: space-around; /* Espaciado alrededor de las imágenes */
  }
}

.color-review {
  color: #385D7F;
}

.carousel-indicators li {
  background-color: #000; /* Negro */
}

/* Cambiar el color de fondo del indicador activo a un color más claro para que se destaque, por ejemplo, gris */
.carousel-indicators .active {
  background-color: #000000; /* Gris oscuro */
}

@media (max-width: 767px) {
  .testimonial-container {
    /* Aquí ajustas el estilo del contenedor para móviles */
    flex-direction: column;
    align-items: center;
  }
}

.carousel-control-next{
  display: none !important;
}

.carousel-control-prev-icon{
  display: none !important;
}




.carousel .carousel-indicators .active{
  background-color: #000 !important;
}

.carousel-indicators [data-bs-target] {
  
  width: 10px !important; /* Anchura del indicador */
  height: 10px !important; /* Altura del indicador */

  border-radius: 50% !important;
}


/* Estilos base para desktop */
.container1 {
  display: flex;
  justify-content: center;
}

.responsive-image1 {
  max-width: 15%;
}

/* Media query para pantallas menores o iguales a 768px (típicamente tablets y móviles) */
@media (max-width: 768px) {
  .container1 {
      justify-content: space-between;
  }

  .responsive-image1 {
      max-width: 20%;
  }
}


.step-indicator {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif; /* or your preferred font */
}

.step-bubble {
  padding: 5px 10px;
  border-radius: 20px; /* circular edges */
  background-color: #ccc;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease; /* smooth transition for background color */
}

.step-bubble.active {
  background-color: #000; /* Active step has blue bg or any color you want */
}

.step-arrow {
  margin: 0 8px;
  color: #333;
  font-weight: normal;
}

/* If the step is completed, you may want to add different styling, e.g., */
.step-bubble.completed {
  background-color: #000; /* Green bg for completed steps */
}


/* Custom CSS to adjust the padding of the Continue button */
.btn-continue {
  padding: 6px 12px !important; /* Adjust padding values to suit your design needs */
  background-color: #DF495A !important;/* Color de fondo */
  border-color: #DF495A !important;
  color: white !important;
}


.step-indicator-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step-icon {
    color: red; /* Change to match the color in your image */
    margin-bottom: 5px;
}

.step-label {
    text-align: center;
    font-size: 0.85rem;
}

.step-line {
  flex-grow: 1;
  height: 2px;
  width: calc(100% + 20px); /* 100% + 10px overflow on each side */
  background-color: #d3d3d3;
  position: relative;
  transform: translateY(-7px); /* If you still want it moved up by 7px */
}

.step-line::after {
    content: '';
    height: 2px;
    width: 50%; /* Adjust based on the current step */
    background-color: red; /* Change to match the color in your image */
    position: absolute;
    left: 0;
    top: 0;
}

.step-line.full::after {
  width: 100%;
}

/* Active step styles */
.step.active .step-icon {
    transform: translateX(20px); /* Shift left by 10px to keep it centered */
    color: red; /* Same active color as the step icon */
}

.step.active .step-label {
  transform: translateX(20px); /* Shift left by 10px to keep it centered */
  font-weight: bold;
  margin-top: 5px;
}


/* Media query for small screens, sm breakpoint */

.banner-garlas-llc-heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #385D7F;
  font-size: 38px;
  line-height: 46px;
}

.banner-garlas-llc-card-paragraph {
  margin: 0;
  color: #ffffff ;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
}

.banner-garlas-llc-card-paragraph-2 {
  text-align: end;
  color: #ffffff;
  margin: 0;
  font-weight: 800;
  font-size: 24px;
  line-height: 26px;
}


/* phones */
@media (max-width: 992px) {
  .banner-garlas-llc-heading {
      font-size: 30px;
      line-height: 40px;
    }

  .banner-garlas-llc-card-paragraph {
    font-size: 18px;
    line-height: 26px;
  }

  .banner-garlas-llc-card-paragraph-2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }
}

/* ultra-wide  */
@media (min-width: 1500px) {
  .banner-garlas-llc-heading {
    font-size: 48px;
    line-height: 54px;
  }

  .banner-garlas-llc-card-paragraph {
    font-size: 28px;
    line-height: 32px;
  }

  .banner-garlas-llc-card-paragraph-2 {
    font-size: 30px;
    line-height: 34px;
  }
}

.logo-img {
  object-fit: contain;
  max-height: 100%;
  width: auto;
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.logo-slider {
  mask-image: linear-gradient(to right, transparent 0, white 10px, white calc(100% - 10px), transparent 100%);
}

.logo-track {
  animation: slide-left 100s linear infinite;
  white-space: nowrap;
}

.logo-slider:hover .logo-track {
  animation-play-state: paused;
}

.logo-slider-img {
  height: 35px;
}

.bg-red {
  background-color: #DF495A !important;
}

.w-side-left {
  width: 50%;
}

.w-side-right {
  width: 50%;
}

@media (max-width: 992px) {
  .w-side-left {
    width: 100%;
  } 
}