.custom-accordion .accordion-item {
    border-radius: 15px; /* Bordes redondeados */
    margin-bottom: 10px; /* Espacio entre elementos del acordeón */
  }
  
  .custom-accordion .accordion-button {
    background-color: #FCDDD4; /* Color de fondo del encabezado */
    color: black; /* Color del texto */
  }
  
  .custom-accordion .accordion-button:not(.collapsed) {
    background-color: #D1DEEB; /* Color de fondo cuando el acordeón está abierto */
    color: black;
  }
  
 
.custom-accordion .accordion-header img.accordion-toggle-icon {
    margin-right: 10px; /* Espacio entre el ícono y el texto */
    width: 24px; /* Tamaño del ícono */
    height: auto;
    transition: transform 0.2s ease; /* Transición suave para rotar ícono */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  
  .custom-accordion .accordion-item {
    border: none; /* Elimina el borde del ítem del acordeón si es necesario */
  }
  
  /* Opcional: Añade estilos para el ícono cuando el acordeón esté expandido */
  .custom-accordion .accordion-button:not(.collapsed) .accordion-toggle-icon {
    transform: rotate(180deg); /* Rota el ícono */
  }

  .custom-accordion .accordion-button::after {
    display: none !important; /* Oculta el ícono de flecha predeterminado */
  }
